import React from 'react'
import Layout from '../components/layout/'
import Header from '../components/header/'
import PageWrapper from '../components/pageWrapper/'
import Button from '../components/button/'
import styles from './styles/contact.module.css'

const Contact = ({ data }) => {
  const stats = data.stats.edges[0].node

  return (
    <Layout>
      <Header stats={stats} />
      <PageWrapper>
        <div className={styles.contentBlock}>
          <h1>Contact</h1>
          <p>Please feel free to contact us for more information.</p>

          <form
            name="contact"
            method="POST"
            data-netlify-honeypot="bot-field"
            data-netlify="true"
            className={styles.formWrapper}
          >
            <label>
              Name
              <input
                type="text"
                name="name"
                placeholder="First and last name"
                required
                pattern="\S+.{2,}"
                title="3 characters minimum"
              />
            </label>
            <label>
              Email
              <input
                type="email"
                name="email"
                placeholder="Email"
                required
                pattern=".{5,}"
                title="5 characters minimum"
              />
            </label>
            <label>
              Phone number
              <input
                type="text"
                pattern=".{9,}[0-9.]+"
                name="phone"
                placeholder="Phone number"
                title="10 numerical characters minimum"
              />
            </label>
            <label>
              Message
              <textarea
                name="project"
                placeholder="Your message here"
                pattern=".{10,}"
                title="10 characters minimum"
              />
            </label>
            <input type="hidden" name="form-name" value="contact" />
            <Button type="submit">Submit</Button>
          </form>

          <p>
            Grand Junction Imagination Library
            <br /> PO Box 2446 <br /> Grand Junction, CO 81502
          </p>
          <p>
            Email:{' '}
            <a href="mailto:info@gjimaginationlibrary.org">
              info@gjimaginationlibrary.org
            </a>
          </p>
          <p>
            Telephone: <a href="tel:9702089128">970-208-9128</a>
          </p>
        </div>
      </PageWrapper>
    </Layout>
  )
}

export const contactPageQuery = graphql`
  query contactPageQuery {
    stats: allContentfulStats {
      edges {
        node {
          id
          booksSent
          kidsRegistered
        }
      }
    }
  }
`

export default Contact
